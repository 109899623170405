// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tagus-primary: mat.define-palette(mat.$indigo-palette);
$tagus-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tagus-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tagus-theme: mat.define-light-theme((color: (primary: $tagus-primary,
        accent: $tagus-accent,
        warn: $tagus-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tagus-theme);

/* You can add global styles to this file, and also import other style files */
:root {
  --fontFamily: 'Open Sans', sans-serif;
  --headingFontFamily: 'Raleway', sans-serif;
  --heraBlueColor: #757fef;
  --aareRiverColor: #00b69b;
  --blackColor: #260944;
  --paragraphColor: #5b5b98;
  --whiteColor: #ffffff;
  --fontSize: 15px;
  --transition: .5s;
  --borderBoxShadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  --borderWhiteBoxShadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: var(--blackColor);
  background-color: #f5f5f5;

  font: {
    size: var(--fontSize);
    family: var(--fontFamily) !important;
  }

  ;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--blackColor);

  font: {
    weight: 700;
    family: var(--headingFontFamily) !important;
  }

  ;

  margin: {
    top: 0;
    bottom: 15px;
  }

  ;

  &:last-child {
    margin-bottom: 0;
  }
}

.h1,
h1 {
  font-size: 38px;
}

.h2,
h2 {
  font-size: 30px;
}

.h3,
h3 {
  font-size: 26px;
}

.h4,
h4 {
  font-size: 22px;
}

.h5,
h5 {
  font-size: 18px;
}

.h6,
h6 {
  font-size: 14px;
}

a {
  transition: var(--transition);
  color: var(--blackColor);
  text-decoration: none;
  outline: 0 !important;

  &:hover {
    text-decoration: none;
    color: var(--heraBlueColor);
  }
}

.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  color: var(--paragraphColor);
  font-size: var(--fontSize);
  line-height: 1.8;

  margin: {
    top: 0;
    bottom: 15px;
  }

  ;

  &:last-child {
    margin-bottom: 0;
  }
}

input {
  font: {
    family: var(--fontFamily);
    size: var(--fontSize);
  }

  ;
}

button {
  font: {
    family: var(--fontFamily) !important;
    size: var(--fontSize) !important;
  }

  ;
}

// Default BTN
.default-btn {
  border: 0;
  font-size: 13.5px;
  border-radius: 5px;
  color: var(--whiteColor);
  padding: 11px 31px 11px 15px;
  transition: var(--transition);
  background-color: var(--heraBlueColor);

  i {
    transform: translateY(-50%);
    position: absolute;
    margin-top: 2px;
    line-height: 1;
    right: 15px;
    top: 50%;
  }

  &:hover {
    background-color: var(--aareRiverColor);
    color: var(--whiteColor);
  }
}

// Utilities CSS
@import "./utilities";

// UI Kit CSS
@import "./ui-kit";

// Form
@import "./form";

// Header CSS
.header-area {
  .header-right-side {
    li {
      .profile-btn {
        &.mat-mdc-icon-button {

          .mat-mdc-button-persistent-ripple,
          .mat-ripple {
            display: none;
          }
        }
      }
    }
  }
}

// Menu Dropdown CSS
.menu-dropdown {
  &.mat-mdc-menu-panel {
    &.mat-mdc-menu-panel {
      width: 330px;
      min-width: unset;
      max-width: unset;
      border-radius: 5px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      overflow: {
        y: auto;
        x: hidden;
      }

      ;

      .menu-header {
        background-color: var(--heraBlueColor);
        border-radius: 5px 5px 0 0;
        padding: 15px;

        h5 {
          color: var(--whiteColor);
          font-size: 18px;
        }

        button {
          color: var(--whiteColor);
          cursor: pointer;
          font-size: 14px;
        }
      }

      .menu-body {
        list-style-type: none;
        padding-left: 0;

        margin: {
          top: 0;
          bottom: 0;
        }

        ;

        li {
          border-bottom: 1px solid #eeeeee;
          transition: var(--transition);
          padding: 15px 35px 15px 15px;

          img {
            width: 50px;
            border-radius: 50%;
          }

          .title {
            margin-left: 14px;

            h6 {
              margin: 0 0 4px;

              font: {
                size: 15px;
                weight: 600;
              }

              ;
            }

            span {
              font-size: 13px;
              position: relative;
              padding-left: 18px;
              color: var(--paragraphColor);

              i {
                left: 0;
                top: 50%;
                line-height: 1;
                margin-top: 0.7px;
                position: absolute;
                transform: translateY(-50%);
              }
            }
          }

          .link-btn {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            display: block;
            position: absolute;
          }

          .close-btn {
            transition: var(--transition);
            color: var(--paragraphColor);
            transform: translateY(-50%);
            position: absolute;
            cursor: pointer;
            right: 15px;
            z-index: 2;
            top: 50%;

            &:hover {
              color: red;
            }
          }

          .icon {
            position: relative;
            font-size: 30px;
            top: 2px;
          }

          &:hover {
            background-color: #f8f8f8;
          }

          &:last-child {
            border-bottom: none;
          }
        }

        .dropdown-item {
          width: 25%;
          flex: 0 0 auto;
          display: block;
          text-align: center;
          color: var(--paragraphColor);

          padding: {
            top: 8px;
            bottom: 8px;
          }

          ;

          span {
            margin-top: 4px;
            font-size: 14px;
          }

          &:hover {
            color: var(--heraBlueColor);
          }
        }

        .d-flex {
          &.flex-wrap {
            padding: 7px;
          }
        }
      }

      .menu-footer {
        border-top: 1px solid #eeeeee;
        text-align: center;

        padding: {
          top: 15px;
          bottom: 16px;
        }

        ;

        a {
          font-size: 14px;
          padding-right: 18px;
          color: var(--heraBlueColor);

          i {
            top: 50%;
            right: 0;
            line-height: 1;
            margin-top: 1px;
            position: absolute;
            transform: translateY(-50%);
          }

          &:hover {
            color: var(--blackColor);
          }
        }
      }

      .mat-mdc-menu-content {
        padding: 0;
      }
    }
  }
}

.profile-menu-dropdown {
  &.mat-mdc-menu-panel {
    &.mat-mdc-menu-panel {
      width: 150px;
      min-width: unset;
      max-width: unset;
      min-height: auto;
      border-radius: 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      overflow: {
        y: auto;
        x: hidden;
      }

      ;

      ul {
        padding-left: 0;
        list-style-type: none;

        li {
          a {
            cursor: pointer;
            display: block;
            font-size: 15px;
            position: relative;
            padding: 7px 13px 7px 37px;
            color: var(--paragraphColor);

            i {
              top: 50%;
              left: 15px;
              position: absolute;
              transform: translateY(-50%);
            }

            &:hover {
              background-color: #f8fafc;
            }
          }
        }
      }

      .mat-mdc-menu-content {
        padding: 10px 0;
      }
    }
  }
}

.language-menu-dropdown {
  &.mat-mdc-menu-panel {
    &.mat-mdc-menu-panel {
      width: 150px;
      min-width: unset;
      max-width: unset;
      min-height: auto;
      border-radius: 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      overflow: {
        y: auto;
        x: hidden;
      }

      ;

      ul {
        padding-left: 0;
        list-style-type: none;

        li {
          position: relative;

          button {
            width: 100%;
            display: block;
            cursor: pointer;
            text-align: start;
            padding: 9px 13px 9px 42px;
            color: var(--paragraphColor);
            transition: var(--transition);
            background-color: transparent;

            font: {
              size: 15px;
              weight: 500;
              family: var(--headingFontFamily);
            }

            ;

            img {
              transform: translateY(-50%);
              position: absolute;
              width: 20px;
              left: 13px;
              top: 50%;
            }

            &:hover {
              background-color: #f8fafc;
            }
          }
        }
      }

      .mat-mdc-menu-content {
        padding: 10px 0;
      }
    }
  }
}

.monthMenu {
  &.mat-mdc-menu-panel {
    &.mat-mdc-menu-panel {
      width: 150px;
      min-width: unset;
      max-width: unset;
      min-height: auto;
      border-radius: 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      overflow: {
        y: auto;
        x: hidden;
      }

      ;

      .mat-mdc-menu-content {
        padding: 10px 0;

        button {
          display: block;
          min-height: auto;
          padding: 6px 18px;
          font-size: var(--fontSize);
          color: var(--paragraphColor);

          .mdc-list-item__primary-text {
            color: var(--paragraphColor);
            transition: var(--transition);
          }

          &:hover {
            .mdc-list-item__primary-text {
              color: var(--heraBlueColor);
            }
          }
        }
      }

      &.dotMenu {
        .mat-mdc-menu-content {
          button {
            padding-left: 36px;

            i {
              top: 50%;
              left: 15px;
              position: absolute;
              transform: translateY(-50%);
            }
          }
        }

        &.two {
          width: 180px;
        }
      }
    }
  }
}

// Sidemenu CSS
@import "./sidebar";

// Mat Card CSS
.mat-mdc-card {
  &.tagus-card {
    box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);
    background-color: var(--whiteColor) !important;
    border-radius: 10px !important;
    display: block !important;
    border: none !important;
    padding: 25px;

    .mat-mdc-card-header {
      padding: 0;
      margin-bottom: 20px;
      align-items: center;
      justify-content: space-between;

      .btn {
        width: auto;
        cursor: pointer;
        max-width: unset;
        min-height: unset;
        max-height: unset;
        height: auto !important;
        min-width: unset !important;
        color: var(--paragraphColor);
        padding: 0 0 2px 23px !important;
        font-size: var(--fontSize) !important;

        &::before {
          left: 0;
          top: 50%;
          content: "\f105";
          position: absolute;
          color: var(--blackColor);
          transform: translateY(-50%);

          font: {
            family: flaticon_tagus;
            weight: 700;
            size: 14px;
          }

          ;
        }

        .mdc-button__ripple,
        .mat-mdc-button-persistent-ripple,
        .mat-ripple {
          display: none;
        }
      }

      .dot-btn {
        top: 2px;
        width: auto;
        cursor: pointer;
        line-height: .01;
        max-width: unset;
        min-height: unset;
        max-height: unset;
        padding: 0 !important;
        height: auto !important;
        font-size: 25px !important;
        min-width: unset !important;
        color: var(--paragraphColor);
        transition: var(--transition);

        &:hover {
          color: var(--heraBlueColor);
        }

        .mdc-button__ripple,
        .mat-mdc-button-persistent-ripple,
        .mat-ripple {
          display: none;
        }
      }

      .sub-title {
        color: var(--paragraphColor);
      }

      .default-btn {
        padding: 14px 25px 14px 46px !important;

        font: {
          weight: 600 !important;
          size: 14px !important;
        }

        ;

        .mdc-button__label {
          display: block;
          position: unset;
        }

        i {
          top: 50%;
          left: 22px;
          right: auto;
          margin-top: 0;
          line-height: 1;
          font-size: 18px;
          position: absolute;
          transform: translateY(-50%);
        }

        &.small {
          padding: 11px 20px 11px 39px !important;

          i {
            left: 18px;
            font-size: 16px;
          }
        }
      }

      .search-box {
        width: 280px;

        .input-search {
          background-color: #f5F7fa;
          padding: 0 15px 2px 15px;
          color: var(--blackColor);
          outline: 0 !important;
          border-radius: 5px;
          height: 41px;
          width: 100%;
          border: 0;

          &::placeholder {
            color: #A9A9C8;
            transition: var(--transition);
          }

          &:focus {
            &::placeholder {
              color: transparent;
            }
          }
        }

        button {
          top: 50%;
          padding: 0;
          right: 20px;
          border: none;
          line-height: 1;
          cursor: pointer;
          position: absolute;
          font-size: 22px !important;
          transform: translateY(-50%);
          color: var(--heraBlueColor);
          background-color: transparent;
          transition: var(--transition);

          &:hover {
            color: var(--blackColor);
          }
        }
      }

      &.border-bottom {
        padding-bottom: 20px;
        border-bottom: 1px solid #eef0f7;
      }

      .mat-mdc-card-header-text {
        display: none;
      }

      .info-list {
        .mat-mdc-icon-button {
          .mat-mdc-button-persistent-ripple {
            border-radius: 4px;
          }
        }
      }
    }

    .mat-mdc-card-content {
      padding: 0;

      .tagus-mat-table {
        .mat-mdc-table {
          .mdc-data-table__header-row {
            .mdc-data-table__header-cell {
              text-align: center;
              font-weight: 600;
              overflow: unset;
              border: none;

              padding: {
                top: 16px;
                bottom: 16px;
              }

              ;

              .mat-mdc-checkbox {
                .mdc-checkbox {
                  margin-left: -10px;

                  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
                    border-color: #a9a9c8;
                  }
                }
              }
            }
          }

          .mdc-data-table__content {
            .mdc-data-table__row {
              .mdc-data-table__cell {
                overflow: unset;
                padding: 15px 20px;
                text-align: center;
                white-space: nowrap;
                color: var(--paragraphColor);
                border-bottom-color: #f7faff;

                .product-info {
                  color: var(--paragraphColor);

                  font: {
                    weight: 600;
                    family: var(--headingFontFamily);
                  }

                  ;

                  img {
                    width: 48px;
                    margin-right: 12px;
                    border-radius: 10px;
                  }

                  &:hover {
                    color: var(--heraBlueColor);
                  }
                }

                .badge {
                  font-size: 14px;
                  border-radius: 4px;
                  padding: 4px 13px 5px;
                  color: var(--heraBlueColor);
                  background: rgba(117, 127, 239, 0.1);

                  &.delivered {
                    background: rgba(0, 182, 155, 0.1);
                    color: #00b69b;
                  }

                  &.outOfStock {
                    color: #ee368c;
                    background: rgba(238, 54, 140, 0.1);
                  }
                }

                .mat-mdc-checkbox {
                  .mdc-checkbox {
                    margin-left: -10px;

                    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
                      border-color: #a9a9c8;
                    }
                  }
                }

                .dot-btn {
                  width: 35px;
                  line-height: 30px;
                  padding: 0 !important;
                  height: 30px !important;
                  color: var(--blackColor);
                  font-size: 18px !important;
                  min-width: unset !important;
                  border-radius: 4px !important;
                  display: inline-block !important;
                  background-color: #F3F6F9 !important;
                }

                .rating {
                  i {
                    line-height: 1;
                    color: #FFBC2B;

                    margin: {
                      left: 2px;
                      right: 2px;
                    }

                    ;
                  }
                }

                .colors {
                  span {
                    width: 10px;
                    height: 10px;
                    font-size: 0;
                    border-radius: 50%;
                    display: inline-block;
                    border: 1px solid #eeeeee;
                    background-color: var(--whiteColor);

                    margin: {
                      left: 2px;
                      right: 2px;
                    }

                    ;

                    &.red {
                      background-color: red;
                      border-color: red;
                    }

                    &.blue {
                      background-color: blue;
                      border-color: blue;
                    }

                    &.green {
                      background-color: green;
                      border-color: green;
                    }

                    &.orange {
                      background-color: orange;
                      border-color: orange;
                    }

                    &.black {
                      background-color: black;
                      border-color: black;
                    }

                    &.yellow {
                      background-color: yellow;
                      border-color: yellow;
                    }

                    &.purple {
                      background-color: purple;
                      border-color: purple;
                    }

                    &.brown {
                      background-color: brown;
                      border-color: brown;
                    }

                    &.pink {
                      background-color: pink;
                      border-color: pink;
                    }

                    &.maroon {
                      background-color: maroon;
                      border-color: maroon;
                    }

                    &.violet {
                      background-color: violet;
                      border-color: violet;
                    }

                    &:first-child {
                      margin-left: 0;
                    }

                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }

              &:last-child {
                .mdc-data-table__cell {
                  border-bottom: 1px solid #f7faff;
                }
              }
            }
          }

          .mat-sort-header-container {
            justify-content: center;

            .mat-sort-header-arrow {
              top: 2px;
            }
          }
        }

        &.recentOrdersTable {
          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                &:first-child {
                  color: var(--heraBlueColor);
                }
              }
            }
          }
        }

        &.teamMembersListTable {
          -webkit-overflow-scrolling: touch;

          overflow: {
            x: auto;
            y: hidden;
          }

          ;

          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                padding: {
                  left: 25px;
                  right: 25px;
                }

                ;

                &:first-child {
                  color: var(--heraBlueColor);
                }
              }
            }

            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  padding: {
                    left: 25px;
                    right: 25px;
                  }

                  ;

                  a {
                    color: var(--paragraphColor);

                    &:hover {
                      color: var(--heraBlueColor);
                    }
                  }

                  .mdc-checkbox {
                    margin-right: 5px;
                  }

                  .role-info {
                    display: inline-block;
                    padding-left: 28px;

                    i,
                    img {
                      left: 0;
                      top: 50%;
                      line-height: 1;
                      font-size: 17px;
                      position: absolute;
                      transform: translateY(-50%);
                      color: var(--heraBlueColor);
                    }
                  }

                  .user-info {
                    margin-right: 25px;
                    color: var(--blackColor);

                    font: {
                      size: 16px;
                      weight: 600;
                      family: var(--headingFontFamily);
                    }

                    ;

                    img {
                      margin-right: 12px;
                    }

                    span {
                      margin-top: 4px;

                      font: {
                        size: 15px;
                        family: var(--fontFamily);
                      }

                      ;
                    }
                  }

                  .badge {
                    background: rgba(0, 182, 155, 0.1);
                    color: #00b69b;

                    &.inactive {
                      color: #ee368c;
                      background: rgba(238, 54, 140, 0.1);
                    }

                    &.expired {
                      color: #757fef;
                      background: rgba(117, 127, 239, 0.1);
                    }

                    &.active {
                      color: #00b69b;
                      background: rgba(0, 182, 155, 0.1);
                    }

                    &.revoked {
                      color: #757fef;
                      background: rgba(117, 127, 239, 0.1);

                    }
                  }
                }
              }
            }
          }
        }

        &.myTasksTable {
          -webkit-overflow-scrolling: touch;

          overflow: {
            x: auto;
            y: hidden;
          }

          ;

          .mdc-data-table__header-row {
            .mdc-data-table__header-cell {
              &:first-child {
                color: var(--heraBlueColor);
              }
            }
          }

          .mdc-data-table__content {
            .mdc-data-table__row {
              .mdc-data-table__cell {
                padding: 12px 20px;

                img {
                  width: 160px;
                  height: 120px;
                }

                .badge {
                  color: #00B69B;
                  background: rgba(0, 182, 155, 0.1);

                  &.pending {
                    color: #EE368C;
                    background: rgba(238, 54, 140, 0.1);
                  }

                  &.completed {
                    color: #757FEF;
                    background: rgba(117, 127, 239, 0.1);
                  }
                }

                .name-info {
                  font: {
                    size: 16px;
                    weight: 600;
                    family: var(--headingFontFamily);
                  }

                  ;
                }

                &:first-child {
                  color: var(--blackColor);
                }
              }
            }
          }
        }

        &.invoiceListsTable {
          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                &:first-child {
                  color: var(--heraBlueColor);
                }
              }
            }

            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  .user-info {
                    .title {
                      margin-left: 12px;

                      h5 {
                        font-size: 16px;
                      }

                      span {
                        margin-top: 5px;
                      }
                    }
                  }

                  .badge {
                    background: rgba(0, 182, 155, 0.1);
                    color: #00b69b;

                    &.pending {
                      color: #ee368c;
                      background: rgba(238, 54, 140, 0.1);
                    }
                  }
                }
              }
            }
          }
        }

        &::-webkit-scrollbar {
          -webkit-appearance: none;

          &:vertical {
            width: 10px;
          }

          &:horizontal {
            height: 10px;
          }
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          border: 2px solid var(--whiteColor);
          background-color: rgba(0, 0, 0, .2);
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: var(--whiteColor);
        }
      }

      .kanban-item {
        .dot-btn {

          .mdc-button__ripple,
          .mat-mdc-button-persistent-ripple,
          .mat-ripple {
            display: none;
          }
        }
      }

      &:last-child {
        padding-bottom: 0;
      }

      &:first-child {
        padding-top: 0;
      }

      .product-tabs {
        &.mat-mdc-tab-group {
          display: block;
          margin-top: 25px;

          .mat-mdc-tab-header {
            margin-bottom: 20px;
            border-bottom: 1px solid #F7FAFF;

            .mat-mdc-tab-label-container {
              .mat-mdc-tab-list {
                .mat-mdc-tab-labels {
                  .mat-mdc-tab {
                    height: auto;
                    display: block;
                    min-width: auto;
                    margin-right: 15px;
                    padding: 15px 30px;
                    border-radius: 5px;
                    background-color: #F5F7FA;
                    transition: var(--transition);

                    font: {
                      size: 18px;
                      weight: 600;
                      family: var(--headingFontFamily);
                    }

                    ;

                    .mdc-tab__text-label {
                      transition: var(--transition);
                      color: var(--blackColor);
                    }

                    .mdc-tab-indicator {
                      display: none;
                    }

                    &:last-child {
                      margin-right: 0;
                    }

                    &.mdc-tab--active {
                      background-color: var(--heraBlueColor);
                      color: var(--whiteColor);

                      .mdc-tab__text-label {
                        color: var(--whiteColor);
                      }
                    }
                  }
                }
              }
            }
          }

          .mat-mdc-tab-body-wrapper {
            .mat-mdc-tab-body {
              overflow: hidden;

              .mat-mdc-tab-body-content {
                overflow: hidden;
              }
            }
          }
        }
      }

      .note-box {
        .mat-mdc-form-field {
          display: block;

          .mat-mdc-form-field-focus-overlay {
            display: none;
          }
        }

        .mdc-text-field--filled {
          &:not(.mdc-text-field--disabled) {
            background-color: transparent;
          }
        }

        .mdc-text-field--focused {
          &:not(.mdc-text-field--disabled) {
            .mdc-floating-label {
              color: var(--heraBlueColor) !important;
            }
          }
        }

        .mat-mdc-text-field-wrapper {
          border-radius: 10px;
          padding: 0 15px 0 60px;
          border: 1px solid #E7EBF5;
        }

        .date-input {
          .mat-mdc-text-field-wrapper {
            padding: 0 15px 0 20px;
          }
        }

        .mat-mdc-icon-button {
          &.mat-mdc-button-base {
            padding: 12px 0 12px 0;
            margin-right: -15px;
          }
        }

        .mdc-line-ripple {
          display: none;
        }

        .mdc-text-field {
          &:not(.mdc-text-field--disabled) {
            .mdc-floating-label {
              color: #A9A9C8;
            }
          }
        }

        i {
          top: 15.5px;
          left: -38px;
          line-height: 1;
          color: #A9A9C8;
          position: absolute;

          font: {
            size: 25px;
            weight: normal;
          }

          ;
        }

        input[type="file"] {
          border: 1px solid #B1B5C3;
          border-radius: 10px;
          padding: 15px 25px;
          cursor: pointer;
          display: block;
          width: 100%;
        }

        .default-btn {
          width: auto;
          max-width: unset;
          min-height: unset;
          max-height: unset;
          height: auto !important;
          color: var(--whiteColor);
          min-width: unset !important;
          padding: 17px 50px !important;
          background-color: var(--heraBlueColor);

          font: {
            family: var(--headingFontFamily) !important;
            weight: 600 !important;
            size: 17px !important;
          }

          ;

          .mdc-button__label {
            display: block;
            position: unset;
          }

          &:hover {
            color: var(--whiteColor);
            background-color: var(--aareRiverColor);
          }
        }
      }
    }

    .status-item {
      #chart {
        .apexcharts-tooltip {
          &.apexcharts-theme-light {
            border: none;
            background: var(--whiteColor);
            box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);

            .apexcharts-tooltip-title {
              padding: 8px 10px;
              border-bottom: none;
              margin-bottom: 3px;
              background: #f7faff;
              color: var(--blackColor);

              font: {
                weight: 600;
                size: 14px !important;
                family: var(--fontFamily) !important;
              }

              ;
            }

            .apexcharts-tooltip-text {
              color: var(--paragraphColor);

              font: {
                size: 14px !important;
                family: var(--fontFamily) !important;
              }

              ;

              .apexcharts-tooltip-text-y-value {
                color: var(--blackColor);
              }
            }

            .apexcharts-tooltip-marker {
              top: 0.5px;
              width: 10px;
              height: 10px;
              margin-right: 6px;
            }
          }
        }
      }
    }

    .mat-mdc-checkbox {
      &.mat-accent {
        .mat-mdc-checkbox-ripple {
          display: none;
        }

        .mdc-form-field {
          color: var(--paragraphColor);
        }
      }
    }

    &::after {
      display: none;
    }

    &.bg-main-color {
      background-color: var(--heraBlueColor) !important;
    }

    &.enrolled-created-box {
      .mat-mdc-tab-group {
        .mat-mdc-tab-header {
          margin-bottom: 15px;

          .mat-mdc-tab-label-container {
            .mat-mdc-tab-list {
              .mat-mdc-tab-labels {
                .mat-mdc-tab {
                  height: auto;
                  display: block;
                  min-width: auto;
                  margin-right: 15px;
                  padding: 16px 35px;
                  border-radius: 5px;
                  background-color: #F5F7FA;
                  transition: var(--transition);

                  font: {
                    size: 18px;
                    weight: 600;
                    family: var(--headingFontFamily);
                  }

                  ;

                  .mdc-tab__text-label {
                    transition: var(--transition);
                    color: var(--blackColor);
                  }

                  .mdc-tab-indicator {
                    display: none;
                  }

                  &:last-child {
                    margin-right: 0;
                  }

                  &.mdc-tab--active {
                    background-color: var(--heraBlueColor);
                    color: var(--whiteColor);

                    .mdc-tab__text-label {
                      color: var(--whiteColor);
                    }
                  }
                }
              }
            }
          }
        }

        .mat-mdc-tab-body-wrapper {
          .mat-mdc-tab-body {
            overflow: hidden;

            .mat-mdc-tab-body-content {
              overflow: hidden;

              .mat-elevation-z8 {
                .mat-mdc-table {
                  .mdc-data-table__header-row {
                    .mdc-data-table__header-cell {
                      &:first-child {
                        text-align: start;
                      }

                      &:nth-child(2) {
                        text-align: center;
                      }
                    }
                  }

                  .mdc-data-table__content {
                    .mdc-data-table__row {
                      .mdc-data-table__cell {
                        .course-info {
                          margin-right: -45px;
                          text-align: start;

                          font: {
                            size: 16px;
                            weight: 600;
                            family: var(--headingFontFamily);
                          }

                          ;

                          img {
                            width: 75px;
                            margin-right: 18px;
                          }

                          a {
                            max-width: 275px;
                            line-height: 1.6;
                            white-space: normal;
                          }
                        }

                        span {
                          margin-bottom: 5px;

                          font: {
                            weight: normal;
                            size: 14px;
                          }

                          ;

                          &:last-child {
                            margin-bottom: 0;
                          }
                        }

                        .badge {
                          border-radius: 4px;
                          padding: 4px 20px 5px;
                          color: var(--heraBlueColor);
                          background: rgba(117, 127, 239, 0.1);

                          &.failed {
                            color: #ee368c;
                            background: rgba(238, 54, 140, 0.1);
                          }

                          &.passed {
                            color: #00B69B;
                            background: rgba(0, 182, 155, 0.1);
                          }
                        }

                        &:nth-child(2) {
                          text-align: center;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }


    form {
      .mat-mdc-form-field {
        display: block;

        .mat-mdc-form-field-focus-overlay {
          display: none;
        }
      }

      .mdc-text-field--filled {
        &:not(.mdc-text-field--disabled) {
          background-color: transparent;
        }
      }

      .mdc-text-field--focused {
        &:not(.mdc-text-field--disabled) {
          .mdc-floating-label {
            color: var(--heraBlueColor) !important;
          }
        }
      }

      .mat-mdc-text-field-wrapper {
        border: 1px solid #B1B5C3;
        border-radius: 10px;
        padding: 0 15px 0 60px;
      }

      .date-input {
        .mat-mdc-text-field-wrapper {
          padding: 0 15px 0 20px;
        }
      }

      .mat-mdc-icon-button {
        &.mat-mdc-button-base {
          padding: 12px 0 12px 0;
          margin-right: -15px;
        }
      }

      .mdc-line-ripple {
        display: none;
      }

      .mdc-text-field {
        &:not(.mdc-text-field--disabled) {
          .mdc-floating-label {
            color: #A9A9C8;
          }
        }
      }

      i {
        top: 15.5px;
        left: -38px;
        line-height: 1;
        color: #A9A9C8;
        position: absolute;

        font: {
          size: 25px;
          weight: normal;
        }

        ;
      }

      input[type="file"] {
        border: 1px solid #B1B5C3;
        border-radius: 10px;
        padding: 15px 25px;
        cursor: pointer;
        display: block;
        width: 100%;
      }
    }

    .default-btn {
      width: auto;
      max-width: unset;
      min-height: unset;
      max-height: unset;
      height: auto !important;
      color: var(--whiteColor);
      min-width: unset !important;
      padding: 17px 50px !important;
      background-color: var(--heraBlueColor);

      font: {
        family: var(--headingFontFamily) !important;
        weight: 600 !important;
        size: 17px !important;
      }

      ;

      .mdc-button__label {
        display: block;
        position: unset;
      }

      &:hover {
        color: var(--whiteColor);
        background-color: var(--aareRiverColor);
      }
    }


    .course-details-desc {
      .mat-accordion {
        .mat-expansion-panel {
          background: #F7FAFF;
          margin-bottom: 15px;
          border-radius: 8px;
          box-shadow: unset;

          &:last-child {
            margin-bottom: 0;
          }

          &:first-of-type {
            border: {
              top: {
                right-radius: 8px;
                left-radius: 8px;
              }
            }

            ;
          }
        }

        .mat-expansion-panel-header {
          height: auto;
          display: block;
          padding: 20px 25px;
          position: relative;

          font: {
            size: 16px;
            weight: 600;
          }

          ;

          .mat-content {
            display: block;
          }
        }

        .mat-expansion-indicator {
          position: absolute;
          right: 25px;
          top: 16px;

          &::after {
            padding: 4px;
          }
        }

        .mat-expansion-panel-header-title,
        .mat-expansion-panel-header-description {
          margin-right: 0;
          display: block;
        }

        .mat-expansion-panel-header-title {
          color: var(--blackColor);
        }

        .mat-expansion-panel-body {
          padding: 5px 25px 25px;

          .content-list {
            list-style: none;

            li {
              border-bottom: 1px solid #E7EBF5;

              padding: {
                top: 15px;
                bottom: 15px;
              }

              ;

              i {
                transform: translateY(-50%);
                color: var(--heraBlueColor);
                position: absolute;
                font-size: 20px;
                top: 50%;
                left: 0;
              }

              span {
                &:nth-child(1) {
                  padding-left: 25px;
                }
              }

              &:last-child {
                border-bottom: none;
                padding-bottom: 0;
              }

              &:first-child {
                padding-top: 0;
              }
            }
          }
        }
      }
    }

    .mat-mdc-row,
    .mdc-data-table__content {
      font-family: var(--fontFamily);
    }
  }
}

// Chart CSS
.chart {
  .apexcharts-datalabel {
    color: var(--blackColor);

    font: {
      family: var(--fontFamily) !important;
      size: 14px;
    }

    ;
  }

  .apexcharts-text {
    color: var(--paragraphColor);

    font: {
      family: var(--fontFamily) !important;
      size: 14px;
    }

    ;
  }

  .apexcharts-title-text {
    font-family: var(--fontFamily) !important;
  }

  .apexcharts-legend-text {
    font-family: var(--fontFamily) !important;
  }

  .apexcharts-legend-marker {
    margin-right: 5px;
  }

  .apexcharts-legend-series {
    margin: 0 10px !important;
  }

  .apexcharts-tooltip {
    &.apexcharts-theme-light {
      border: none;
      background: var(--whiteColor);
      box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);

      .apexcharts-tooltip-title {
        padding: 8px 10px;
        border-bottom: none;
        margin-bottom: 3px;
        background: #f7faff;
        color: var(--blackColor);

        font: {
          weight: 600;
          size: 14px !important;
          family: var(--fontFamily) !important;
        }

        ;
      }

      .apexcharts-tooltip-text {
        color: var(--paragraphColor);

        font: {
          size: 14px !important;
          family: var(--fontFamily) !important;
        }

        ;

        .apexcharts-tooltip-text-y-value {
          color: var(--blackColor);
        }
      }

      .apexcharts-tooltip-marker {
        top: 0.5px;
        width: 10px;
        height: 10px;
        margin-right: 6px;
      }
    }
  }

  .apexcharts-xaxistooltip,
  .apexcharts-yaxistooltip {
    border: 0;
    background-color: var(--whiteColor);
    box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);

    .apexcharts-xaxistooltip-text {
      color: var(--blackColor);

      font: {
        weight: 600;
        size: 14px !important;
        family: var(--fontFamily) !important;
      }

      ;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  .apexcharts-tooltip-text {
    font-family: var(--fontFamily) !important;
  }

  &.visitsByDayChart {
    margin-bottom: -25px !important;
  }

  &.liveVisitsChart {
    padding-bottom: 5px;

    .apexcharts-text {
      font-size: 13px;
    }

    .apexcharts-tooltip {
      .apexcharts-tooltip-text {
        font: {
          size: 14px !important;
          family: var(--fontFamily) !important;
        }

        ;
      }
    }

    .apexcharts-datalabel,
    .apexcharts-datalabel-label,
    .apexcharts-datalabel-value,
    .apexcharts-datalabels,
    .apexcharts-pie-label {
      font-family: var(--fontFamily) !important;
    }
  }

  &.yourProgressChart {
    margin-bottom: -10px !important;
  }

  &.hoursSpentChart {
    margin-bottom: -20px !important;
  }

  &.salesAnalyticsChart {
    margin-bottom: -25px !important;
  }

  &.tasksPerformanceChart {
    .apexcharts-legend-marker {
      top: 1px !important;
    }

    .apexcharts-legend-series {
      margin: 0 10px 8px !important;
    }
  }

  &.marketGraphChart {
    margin-bottom: -10px !important;

    .apexcharts-tooltip {
      &.apexcharts-theme-light {
        box-shadow: 0px 10px 35px rgba(50, 110, 189, 0.2);
        border-radius: 10px;

        .apexcharts-custom-tooltip,
        .apexcharts-tooltip-box {
          padding: 15px 18px;

          >div {
            margin: 0 0 11px;
            font-weight: 600;
            color: var(--paragraphColor);

            .value {
              font-weight: normal;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .apexcharts-ycrosshairs-hidden,
    .apexcharts-ycrosshairs {
      stroke: transparent;
      display: none;
    }
  }

  &.ticketsStatusChart {
    margin: {
      bottom: -30px !important;
      top: -10px !important;
    }

    ;
  }

  &.revenueSummaryChart {
    margin: {
      bottom: -30px !important;
      top: -5px !important;
    }

    ;

    .apexcharts-datalabel {
      font: {
        size: 12px;
      }

      ;
    }
  }
}

// Editor
.NgxEditor__Wrapper {
  border: 1px solid #B1B5C3 !important;
  border-radius: 10px !important;

  .NgxEditor__MenuBar {
    border-color: #B1B5C3 !important;

    border: {
      top: {
        left-radius: 10px !important;
        right-radius: 10px !important;
      }
    }

    ;
  }

  .NgxEditor {
    border: {
      bottom: {
        left-radius: 10px !important;
        right-radius: 10px !important;
      }
    }

    ;
  }

  .NgxEditor__Content {
    padding: 15px !important;
  }
}

quill-editor {
  width: 100%;

  .ql-toolbar {
    &.ql-snow {
      border-color: #B1B5C3;
      border-radius: 10px 10px 0 0;
      font-family: var(--fontFamily);
    }
  }

  .ql-container {
    font: {
      family: var(--fontFamily);
      size: var(--fontSize);
    }

    ;

    &.ql-snow {
      border-color: #B1B5C3;
      border-radius: 0 0 10px 10px;
    }
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: var(--heraBlueColor);
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: var(--heraBlueColor);
  }
}

// Breadcrumb CSS
.breadcrumb-card {
  .breadcrumb {
    list-style-type: none;
    margin-top: 0;

    .breadcrumb-item {
      display: inline-block;
      position: relative;

      font: {
        size: 14px;
        family: var(--headingFontFamily);
      }

      ;

      margin: {
        left: 10px;
        right: 10px;
      }

      ;

      &::before {
        top: 50%;
        width: 5px;
        height: 5px;
        left: -13px;
        content: '';
        position: absolute;
        border-radius: 50%;
        background: #A0A0A0;
        transform: translateY(-50%);
      }

      a {
        color: var(--paragraphColor);

        &:hover {
          color: var(--heraBlueColor);
        }
      }

      &:first-child {
        margin-left: 0;

        &::before {
          display: none;
        }

        a {
          position: relative;
          padding-left: 22px;

          i {
            left: 0;
            top: 50%;
            font-size: 16px;
            position: absolute;
            transform: translateY(-50%);
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

// Create Dialog Box
.create-dialog-box {
  .title {
    background: #F7F8F7;
    padding: 25px;

    .close-btn {
      transition: var(--transition);
      font-size: 18px !important;
      cursor: pointer;
      line-height: 1;

      &:hover {
        color: var(--heraBlueColor);
      }
    }
  }

  form {
    padding: 25px;

    .form-group {
      margin-bottom: 25px;

      label {
        color: var(--blackColor);
        margin-bottom: 10px;
      }

      .input-control {
        outline: 0;
        width: 100%;
        height: 55px;
        display: block;
        box-shadow: unset;
        border-radius: 10px;
        color: var(--blackColor);
        font-size: var(--fontSize);
        border: 1px solid #B1B5C3;
        transition: var(--transition);

        padding: {
          left: 20px;
          right: 20px;
        }

        ;

        &::placeholder {
          color: #A9A9C8;
          transition: var(--transition);
        }

        &:focus {
          border-color: var(--heraBlueColor);

          &::placeholder {
            color: transparent;
          }
        }

        &[type="file"] {
          line-height: 55px;
        }
      }

      .input-folder-name {
        outline: 0;
        width: 100%;
        height: 55px;
        display: block;
        box-shadow: unset;
        border-radius: 10px;
        color: var(--blackColor);
        font-size: var(--fontSize);
        border: 1px solid #B1B5C3;
        transition: var(--transition);

        padding: {
          left: 20px;
          right: 20px;
        }

        ;

        &::placeholder {
          color: #A9A9C8;
          transition: var(--transition);
        }

        &:focus {
          border-color: var(--heraBlueColor);

          &::placeholder {
            color: transparent;
          }
        }
      }
    }

    button:not(.mdc-switch) {
      background-color: var(--heraBlueColor);
      transition: var(--transition);
      padding: 15px 28px 15px 50px;
      color: var(--whiteColor);
      display: inline-block;
      position: relative;
      border-radius: 8px;
      cursor: pointer;
      border: 0;

      font: {
        weight: 600;
        size: 14px !important;
      }

      ;

      i {
        top: 50%;
        left: 25px;
        line-height: 1;
        font-size: 18px;
        position: absolute;
        transform: translateY(-50%);
      }

      &:hover {
        color: var(--whiteColor);
        background-color: var(--aareRiverColor);
      }

      &.gray {
        background-color: #EDEFF5;
        color: var(--blackColor);
        margin-right: 15px;

        &:hover {
          background-color: #EDEFF5;
          color: var(--blackColor);
        }
      }
    }
  }

  iframe {
    height: 338px;
    width: 100%;
    border: 0;
  }
}

// Calendar CSS
.fc {
  .fc-header-toolbar {
    .fc-button {
      box-shadow: unset !important;
      text-transform: capitalize;
    }
  }

  .fc-scrollgrid {
    border-color: #EDEFF5 !important;
  }

  .fc-daygrid-day-number {
    font-weight: 600;
    color: var(--blackColor);
  }

  .fc-daygrid-day {
    &.fc-day-today {
      background: #F7FAFF !important;
    }
  }

  .fc-h-event {
    border-radius: 0;
    margin: 0 !important;
    border-color: transparent;
    background-color: transparent;

    .fc-event-main {
      color: var(--blackColor);
    }
  }

  .fc-daygrid-block-event {

    .fc-event-time,
    .fc-event-title {
      padding: 0 15px;
      white-space: normal;
      color: var(--heraBlueColor);

      font: {
        size: 15px;
        weight: 600;
      }

      ;
    }
  }

  td {
    &:nth-child(2) {
      .fc-daygrid-block-event {

        .fc-event-time,
        .fc-event-title {
          color: #00B69B;
        }
      }
    }

    &:nth-child(3) {
      .fc-daygrid-block-event {

        .fc-event-time,
        .fc-event-title {
          color: #6200EE;
        }
      }
    }

    &:nth-child(4) {
      .fc-daygrid-block-event {

        .fc-event-time,
        .fc-event-title {
          color: #FFBC2B;
        }
      }
    }

    &:nth-child(5) {
      .fc-daygrid-block-event {

        .fc-event-time,
        .fc-event-title {
          color: #FF5E6F;
        }
      }
    }

    &:nth-child(7) {
      .fc-daygrid-block-event {

        .fc-event-time,
        .fc-event-title {
          color: #02A0FC;
        }
      }
    }
  }

  &.fc-theme-standard {
    th {
      .fc-scrollgrid-sync-inner {
        padding: {
          top: 12px;
          bottom: 12px;
        }

        ;
      }
    }

    td,
    th {
      border-color: #EDEFF5;
    }
  }
}

// Owl Carousel
.owl-theme {
  .owl-nav {
    margin-top: 0 !important;

    [class*=owl-] {
      top: 50%;
      margin: 0;
      left: 15px;
      padding: 0;
      border: none;
      line-height: 1;
      font-size: 28px;
      border-radius: 0;
      position: absolute;
      color: var(--heraBlueColor);
      transform: translateY(-50%);
      transition: var(--transition);
      background-color: transparent;

      &.owl-next {
        left: auto;
        right: 15px;
      }

      &:hover {
        background-color: transparent;
        color: var(--aareRiverColor);
      }
    }
  }

  .owl-dots {
    margin-top: 15px;

    .owl-dot {
      span {
        width: 12px;
        height: 12px;
        margin: 0 4px;
        border-radius: 50%;
        background: #D6D6D6;
        transition: var(--transition);
      }

      &:hover,
      &.active {
        span {
          background-color: var(--heraBlueColor);
        }
      }
    }
  }
}

// Dark Mode
.dark-theme {
  background-color: #0d1015;
  color: var(--whiteColor);

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--whiteColor);
  }

  p {
    color: #828690;
  }

  input {
    color: var(--whiteColor);
    background-color: #181b20;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  a {
    color: var(--whiteColor);

    &:hover {
      color: var(--heraBlueColor);
    }
  }

  // Default BTN
  .default-btn {
    &:hover {
      color: var(--whiteColor);
    }
  }

  // Mat Card
  .mat-mdc-card {
    --mdc-elevated-card-container-color: #14171c;

    .mat-mdc-card-subtitle {
      color: rgba(255, 255, 255, 0.54);
    }

    &.tagus-card {
      box-shadow: unset;
      background-color: #14171c !important;

      .mat-mdc-card-header {
        .btn {
          color: #828690;

          &::before {
            color: #828690;
          }
        }

        .dot-btn {
          color: #828690;

          &:hover {
            color: var(--heraBlueColor);
          }
        }

        .sub-title {
          color: #828690;
        }

        .search-box {
          .input-search {
            background-color: #1e2227;
            color: var(--whiteColor);

            &::placeholder {
              color: #828690;
            }

            &:focus {
              &::placeholder {
                color: transparent;
              }
            }
          }

          button {
            &:hover {
              color: var(--whiteColor);
            }
          }
        }

        &.border-bottom {
          border-bottom-color: #1f2226;
        }
      }

      .mat-mdc-card-content {
        .tagus-mat-table {
          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                .mat-mdc-checkbox {
                  .mdc-checkbox {
                    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
                      border-color: #a9a9c8;
                    }
                  }
                }
              }
            }

            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  color: #828690;
                  border-bottom-color: #1f2226;

                  .product-info {
                    color: #828690;

                    &:hover {
                      color: var(--heraBlueColor);
                    }
                  }

                  .mat-mdc-checkbox {
                    .mdc-checkbox {
                      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
                        border-color: #a9a9c8;
                      }
                    }
                  }

                  .dot-btn {
                    color: var(--whiteColor);
                    background-color: #1d2024 !important;
                  }
                }

                &:last-child {
                  .mdc-data-table__cell {
                    border-bottom-color: #1f2226;
                  }
                }
              }
            }
          }

          &.recentOrdersTable {
            .mat-mdc-table {
              .mdc-data-table__header-row {
                .mdc-data-table__header-cell {
                  &:first-child {
                    color: var(--heraBlueColor);
                  }
                }
              }
            }
          }

          &.teamMembersListTable {
            .mat-mdc-table {
              .mdc-data-table__header-row {
                .mdc-data-table__header-cell {
                  &:first-child {
                    color: var(--heraBlueColor);
                  }
                }
              }

              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    a {
                      color: #828690;

                      &:hover {
                        color: var(--heraBlueColor);
                      }
                    }

                    .user-info {
                      color: var(--whiteColor);
                    }
                  }
                }
              }
            }
          }

          &.myTasksTable {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                &:first-child {
                  color: var(--heraBlueColor);
                }
              }
            }

            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  &:first-child {
                    color: var(--whiteColor);
                  }
                }
              }
            }
          }

          &.invoiceListsTable {
            .mat-mdc-table {
              .mdc-data-table__header-row {
                .mdc-data-table__header-cell {
                  &:first-child {
                    color: var(--heraBlueColor);
                  }
                }
              }
            }
          }

          &::-webkit-scrollbar-thumb {
            border-color: #1f2226;
            background-color: rgba(255, 255, 255, .2);
          }

          &::-webkit-scrollbar-track {
            background-color: #1f2226;
          }
        }

        .product-tabs {
          &.mat-mdc-tab-group {
            .mat-mdc-tab-header {
              border-bottom-color: #1f2226;

              .mat-mdc-tab-label-container {
                .mat-mdc-tab-list {
                  .mat-mdc-tab-labels {
                    .mat-mdc-tab {
                      background-color: #1d2024;

                      .mdc-tab__text-label {
                        color: var(--whiteColor);
                      }

                      &.mdc-tab--active {
                        background-color: var(--heraBlueColor);
                        color: var(--whiteColor);

                        .mdc-tab__text-label {
                          color: var(--whiteColor);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .note-box {
          .mdc-text-field--focused {
            &:not(.mdc-text-field--disabled) {
              .mdc-floating-label {
                color: var(--heraBlueColor) !important;
              }
            }
          }

          .mat-mdc-text-field-wrapper {
            border-color: #1f2226;
          }

          .mdc-text-field {
            &:not(.mdc-text-field--disabled) {
              .mdc-floating-label {
                color: #828690;
              }
            }
          }

          i {
            color: #828690;
          }

          input[type="file"] {
            border-color: #1f2226;
            background-color: #14171c;
          }
        }
      }

      .status-item {
        #chart {
          .apexcharts-tooltip {
            &.apexcharts-theme-light {
              border: none;
              background: var(--whiteColor);
              box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);

              .apexcharts-tooltip-title {
                padding: 8px 10px;
                border-bottom: none;
                margin-bottom: 3px;
                background: #f7faff;
                color: var(--blackColor);

                font: {
                  weight: 600;
                  size: 14px !important;
                  family: var(--fontFamily) !important;
                }

                ;
              }

              .apexcharts-tooltip-text {
                color: #828690;

                font: {
                  size: 14px !important;
                  family: var(--fontFamily) !important;
                }

                ;

                .apexcharts-tooltip-text-y-value {
                  color: var(--blackColor);
                }
              }

              .apexcharts-tooltip-marker {
                top: 0.5px;
                width: 10px;
                height: 10px;
                margin-right: 6px;
              }
            }
          }
        }
      }

      .mat-mdc-checkbox {
        &.mat-accent {
          .mdc-form-field {
            color: #828690;
          }
        }
      }

      &.enrolled-created-box {
        .mat-mdc-tab-group {
          .mat-mdc-tab-header {
            .mat-mdc-tab-label-container {
              .mat-mdc-tab-list {
                .mat-mdc-tab-labels {
                  .mat-mdc-tab {
                    background-color: #1d2024;

                    .mdc-tab__text-label {
                      color: var(--whiteColor);
                    }

                    &.mdc-tab--active {
                      background-color: var(--heraBlueColor);
                      color: var(--whiteColor);

                      .mdc-tab__text-label {
                        color: var(--whiteColor);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }


      form {
        .mat-mdc-text-field-wrapper {
          border-color: #1f2226;
        }

        .mdc-text-field {
          &:not(.mdc-text-field--disabled) {
            .mdc-floating-label {
              color: #828690;
            }
          }
        }

        i {
          color: #828690;
        }

        input[type="file"] {
          border-color: #1f2226;
          background-color: #14171c;
        }
      }


      .course-details-desc {
        .mat-accordion {
          .mat-expansion-panel {
            background: #1e2227;
          }

          .mat-expansion-panel-header-title {
            color: var(--whiteColor);
          }

          .mat-expansion-panel-body {
            .content-list {
              li {
                border-bottom-color: #1f2226;
              }
            }
          }
        }
      }
    }

    &.bg-main-color {
      background-color: var(--heraBlueColor) !important;
    }
  }

  // Breadcrumb CSS
  .breadcrumb-card {
    .breadcrumb {
      .breadcrumb-item {
        &::before {
          background: #A0A0A0;
        }

        a {
          color: #bdbdbd;

          &:hover {
            color: var(--heraBlueColor);
          }
        }
      }
    }
  }

  // Owl Carousel
  .owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background: #1e2227;
        }

        &:hover,
        &.active {
          span {
            background-color: var(--heraBlueColor);
          }
        }
      }
    }
  }

  // Chart CSS
  .chart {
    .apexcharts {
      &-datalabel {
        color: var(--whiteColor);
      }

      &-text {
        color: #828690;
        fill: #828690;
      }

      &-pie-label {
        color: var(--whiteColor);
        fill: var(--whiteColor);
      }

      &-tooltip {
        &.apexcharts-theme-light {
          background: #1e2227;

          .apexcharts-tooltip-title {
            background: #0d1015;
            color: var(--whiteColor);
          }

          .apexcharts-tooltip-text {
            color: #828690;

            .apexcharts-tooltip-text-y-value {
              color: var(--whiteColor);
            }
          }
        }
      }

      &-xaxistooltip,
      &-yaxistooltip {
        background-color: #1e2227;

        .apexcharts-xaxistooltip-text {
          color: var(--whiteColor);
        }
      }

      &-legend-text {
        color: var(--whiteColor) !important;
      }

      &-grid-borders {
        line {
          stroke: rgb(69, 69, 69);
        }
      }

      &-gridlines-horizontal {
        line {
          stroke: rgb(69, 69, 69);
        }
      }

      &-gridlines-vertical {
        line {
          stroke: rgb(69, 69, 69);
        }
      }

      &-yaxis {
        line {
          stroke: rgb(69, 69, 69);
        }
      }

      &-menu {
        background: #1e2227;
        border-color: #1f2226;
      }

      &-menu-icon,
      &-reset-icon,
      &-zoom-icon,
      &-zoomin-icon,
      &-zoomout-icon {
        svg {
          fill: var(--whiteColor) !important;
        }
      }

      &-pie {

        line,
        circle {
          stroke: rgb(69, 69, 69);
        }
      }

      &-radar-series {

        line,
        polygon {
          stroke: rgb(69, 69, 69);
        }
      }

      &-title-text {
        fill: var(--whiteColor);
      }
    }
  }

  // Calendar CSS
  .fc {
    .fc-scrollgrid {
      border-color: #1f2226 !important;
    }

    .fc-daygrid-day-number {
      color: var(--whiteColor);
    }

    .fc-daygrid-day {
      &.fc-day-today {
        background: #1e2227 !important;
      }
    }

    .fc-h-event {
      .fc-event-main {
        color: var(--whiteColor);
      }
    }

    &.fc-theme-standard {

      td,
      th {
        border-color: #1f2226;
      }
    }
  }

  // Editor
  .NgxEditor__Wrapper {
    border-color: #1f2226 !important;

    .NgxEditor__MenuBar {
      background: #14171c;
      border-color: #1f2226 !important;
    }

    .NgxEditor {
      background: #14171c;
      color: var(--whiteColor);
    }

    .NgxEditor__Seperator {
      border-left-color: #1f2226;
    }
  }

  quill-editor {
    width: 100%;

    .ql-toolbar {
      &.ql-snow {
        border-color: #1f2226;
      }
    }

    .ql-container {
      &.ql-snow {
        border-color: #1f2226;
      }
    }

    .ql-editor {
      &.ql-blank {
        &::before {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  // Borderd Card Theme
  &.card-borderd-theme {
    .mat-mdc-card {
      &.tagus-card {
        box-shadow: var(--borderWhiteBoxShadow);
      }
    }
  }

}

// Borderd Card Theme
.card-borderd-theme {
  .mat-mdc-card {
    &.tagus-card {
      box-shadow: var(--borderBoxShadow);
    }
  }
}

// Border Radius Card Theme
.card-border-radius {
  .mat-mdc-card {
    border-radius: 0 !important;

    &.tagus-card {
      border-radius: 0 !important;
    }
  }
}

// Keyframes CSS
@keyframes ripple {

  0%,
  35% {
    transform: scale(0);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    transform: scale(4);
  }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {

  body {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

  input {
    font-size: 14px;
  }

  .h1,
  h1 {
    font-size: 36px;
  }

  .h2,
  h2 {
    font-size: 28px;
  }

  .h3,
  h3 {
    font-size: 24px;
  }

  .h4,
  h4 {
    font-size: 20px;
  }

  .h5,
  h5 {
    font-size: 16px;
  }

  .h6,
  h6 {
    font-size: 12px;
  }

  button {
    font-size: 14px !important;
  }

  // Default BTN
  .default-btn {
    font-size: 13px;
    padding: 11px 31px 11px 15px;

    i {
      margin-top: 2px;
      right: 15px;
    }
  }

  // Menu Dropdown CSS
  .menu-dropdown {
    &.mat-mdc-menu-panel {
      &.mat-mdc-menu-panel {
        border-radius: 5px 5px 0 0;
        width: 250px;

        .menu-header {
          border-radius: 5px 5px 0 0;
          padding: 12px;

          h5 {
            font-size: 15px;
          }

          button {
            font-size: 13px;
          }
        }

        .menu-body {
          li {
            padding: 10px 28px 10px 12px;

            img {
              width: 45px;
            }

            .title {
              margin-left: 12px;

              h6 {
                font-size: 13.5px;
              }

              span {
                font-size: 12px;
                padding-left: 16px;

                i {
                  margin-top: 1px;
                }
              }
            }

            .close-btn {
              right: 10px;
            }

            .icon {
              font-size: 25px;
            }
          }

          .dropdown-item {
            padding: {
              top: 5px;
              bottom: 5px;
            }

            ;

            span {
              margin-top: 3px;
              font-size: 13px;
            }
          }

          .d-flex {
            &.flex-wrap {
              padding: 5px;
            }
          }
        }

        .menu-footer {
          padding: {
            top: 11px;
            bottom: 12px;
          }

          ;

          a {
            font-size: 13px;
            padding-right: 15px;
          }
        }
      }
    }
  }

  .profile-menu-dropdown {
    &.mat-mdc-menu-panel {
      &.mat-mdc-menu-panel {
        width: 120px;

        ul {
          li {
            a {
              font-size: 14px;
              padding: 6px 10px 6px 31px;

              i {
                left: 10px;
              }
            }
          }
        }

        .mat-mdc-menu-content {
          padding: 5px 0;
        }
      }
    }
  }

  .language-menu-dropdown {
    &.mat-mdc-menu-panel {
      &.mat-mdc-menu-panel {
        width: 130px;

        ul {
          li {
            button {
              padding: 8px 15px 8px 40px;
              font-size: 14px;

              img {
                width: 18px;
                left: 15px;
              }
            }
          }
        }
      }
    }
  }

  .monthMenu {
    &.mat-mdc-menu-panel {
      &.mat-mdc-menu-panel {
        width: 135px;

        .mat-mdc-menu-content {
          button {
            padding: 5px 15px;
            font-size: 14px;
          }
        }

        &.dotMenu {
          .mat-mdc-menu-content {
            button {
              padding-left: 35px;
            }
          }
        }
      }
    }
  }

  // Mat Card CSS
  .mat-mdc-card {
    &.tagus-card {
      padding: 15px;

      .mat-mdc-card-header {
        margin-bottom: 15px;

        .btn {
          padding: 0 0 2px 18px !important;
          font-size: 14px !important;

          &::before {
            font-size: 12px;
            margin-top: -1px;
          }
        }

        .dot-btn {
          top: 1px;
          font-size: 22px !important;
        }

        &.border-bottom {
          padding-bottom: 15px;
          border-bottom: 1px solid #eef0f7;
        }

        .default-btn {
          padding: 12px 18px 12px 35px !important;

          font: {
            size: 13px !important;
          }

          ;

          i {
            left: 15px;
            font-size: 15px;
          }
        }
      }

      .mat-mdc-card-content {
        .tagus-mat-table {
          -webkit-overflow-scrolling: touch;

          overflow: {
            x: auto;
            y: hidden;
          }

          ;

          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                padding: 12px 15px;
                white-space: nowrap;

                &::after {
                  height: 15px;
                }
              }
            }

            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  font-size: 13.5px;
                  padding: 12px 15px;
                  white-space: nowrap;

                  .product-info {
                    margin-right: 0;
                    padding-right: 35px;

                    img {
                      width: 40px;
                      margin-right: 10px;
                      border-radius: 5px;
                    }
                  }

                  .badge {
                    font-size: 13px;
                  }
                }
              }
            }
          }

          .mat-mdc-paginator-outer-container {
            margin-top: 5px;

            .mat-mdc-paginator-container {
              .mat-mdc-icon-button {
                &.mat-mdc-button-base {
                  width: 38px;
                  height: 38px;
                  padding: 5px;
                }
              }
            }
          }

          &.teamMembersListTable {
            .mat-mdc-table {
              .mdc-data-table__header-row {
                .mdc-data-table__header-cell {
                  padding: {
                    left: 15px;
                    right: 15px;
                  }

                  ;
                }
              }

              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    padding: {
                      left: 15px;
                      right: 15px;
                    }

                    ;

                    .role-info {
                      padding-left: 27px;

                      i,
                      img {
                        font-size: 15px;
                      }
                    }

                    .user-info {
                      padding-right: 35px;
                      margin-right: 0;
                      font-size: 14px;

                      img {
                        width: 42px;
                        margin-right: 12px;
                      }

                      span {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
            }
          }

          &.myTasksTable {
            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  padding: 12px 15px;

                  .badge {
                    padding: 4px 18px 5px;
                  }

                  .name-info {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          &.invoiceListsTable {
            .mat-mdc-table {
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    .user-info {
                      padding-right: 40px;
                    }
                  }
                }
              }
            }
          }
        }

        .product-tabs {
          &.mat-mdc-tab-group {
            margin-top: 20px;

            .mat-mdc-tab-header {
              margin-bottom: 15px;

              .mat-mdc-tab-label-container {
                .mat-mdc-tab-list {
                  .mat-mdc-tab-labels {
                    .mat-mdc-tab {
                      font-size: 15px;
                      padding: 12px 20px;
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }

        .note-box {
          .mat-mdc-text-field-wrapper {
            padding: 0 15px 0 45px;
          }

          i {
            top: 16px;
            left: -30px;
            font-size: 23px;
          }

          .default-btn {
            font-size: 15px !important;
            padding: 15px 35px !important;
          }
        }
      }

      &.enrolled-created-box {
        .mat-mdc-tab-group {
          .mat-mdc-tab-header {
            .mat-mdc-tab-label-container {
              .mat-mdc-tab-list {
                .mat-mdc-tab-labels {
                  .mat-mdc-tab {
                    font-size: 15px;
                    padding: 12px 20px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }

          .mat-mdc-tab-body-wrapper {
            .mat-mdc-tab-body {
              .mat-mdc-tab-body-content {
                .mat-elevation-z8 {
                  .mat-mdc-table {
                    .mdc-data-table__content {
                      .mdc-data-table__row {
                        .mdc-data-table__cell {
                          .course-info {
                            margin-right: 0;
                            display: block !important;

                            font: {
                              size: 14px;
                            }

                            ;

                            img {
                              display: block;

                              margin: {
                                right: 0;
                                bottom: 12px;
                              }

                              ;
                            }

                            a {
                              max-width: 100%;
                              white-space: nowrap;
                            }
                          }

                          .badge {
                            padding: 4px 15px 5px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .course-details-desc {
        .mat-accordion {
          .mat-expansion-panel-header {
            padding: 15px;
            font-size: 14px;
          }

          .mat-expansion-indicator {
            right: 15px;
            top: 12px;

            &::after {
              padding: 3px;
            }
          }

          .mat-expansion-panel-body {
            padding: 5px 15px 15px;

            .content-list {

              li {
                i {
                  font-size: 15px;
                }

                span {
                  &:nth-child(1) {
                    padding-left: 19px;
                  }
                }
              }
            }
          }

          .mat-expansion-panel {
            &:last-child {
              margin-bottom: 15px;
            }
          }
        }
      }


      form {
        .mat-mdc-text-field-wrapper {
          padding: 0 15px 0 45px;
        }

        i {
          top: 16px;
          left: -30px;
          font-size: 23px;
        }
      }

      .default-btn {
        font-size: 15px !important;
        padding: 15px 35px !important;
      }

    }
  }

  // Chart CSS
  .chart {
    .apexcharts-datalabel {
      font: {
        size: 13px;
      }

      ;
    }

    .apexcharts-text {
      font: {
        size: 13px;
      }

      ;
    }

    .apexcharts-tooltip {
      &.apexcharts-theme-light {
        .apexcharts-tooltip-title {
          font: {
            size: 13px !important;
          }

          ;
        }

        .apexcharts-tooltip-text {
          font: {
            size: 13px !important;
          }

          ;
        }
      }
    }

    .apexcharts-xaxistooltip,
    .apexcharts-yaxistooltip {
      .apexcharts-xaxistooltip-text {
        font: {
          size: 14px !important;
        }

        ;
      }
    }

    &.liveVisitsChart {
      .apexcharts-text {
        font-size: 13px;
      }

      .apexcharts-tooltip {
        .apexcharts-tooltip-text {
          font: {
            size: 14px !important;
          }

          ;
        }
      }
    }

    &.visitsByDayChart {
      margin-bottom: -30px;
    }
  }

  // Breadcrumb CSS
  .breadcrumb-card {
    .breadcrumb {
      margin-top: 12px;
    }
  }

  // Create Dialog Box
  .create-dialog-box {
    .title {
      padding: 15px;

      .close-btn {
        font-size: 14px !important;
      }
    }

    form {
      padding: 15px;

      .form-group {
        margin-bottom: 15px;

        .input-folder-name {
          height: 48px;
          font-size: 14px;

          padding: {
            left: 15px;
            right: 15px;
          }

          ;
        }

        .input-control {
          height: 48px;
          font-size: 14px;

          padding: {
            left: 15px;
            right: 15px;
          }

          ;

          &[type="file"] {
            line-height: 50px;
          }
        }
      }

      button:not(.mdc-switch) {
        padding: 14px 22px 14px 41px;
        font-size: 13px !important;

        i {
          left: 20px;
          font-size: 16px;
        }
      }
    }

    &.scrollable-dialog {
      max-height: 65vh;
    }

    iframe {
      height: 140px;
    }
  }

  // Calendar CSS
  .fc {
    .fc-toolbar-title {
      font-size: 1.3em !important;
    }

    .fc-daygrid-block-event {

      .fc-event-time,
      .fc-event-title {
        padding: 0 5px;
        font-size: 13px;
        white-space: nowrap;
      }
    }
  }
}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {

  body {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

  input {
    font-size: 14px;
  }

  button {
    font-size: 14px !important;
  }

  // Menu Dropdown CSS
  .menu-dropdown {
    &.mat-mdc-menu-panel {
      &.mat-mdc-menu-panel {
        .menu-header {
          h5 {
            font-size: 16px;
          }

          button {
            font-size: 13px;
          }
        }

        .menu-body {
          li {
            .title {
              margin-left: 12px;

              h6 {
                font-size: 14px;
              }

              span {
                i {
                  margin-top: 1px;
                }
              }
            }
          }
        }
      }
    }
  }

  .profile-menu-dropdown {
    &.mat-mdc-menu-panel {
      &.mat-mdc-menu-panel {
        ul {
          li {
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .language-menu-dropdown {
    &.mat-mdc-menu-panel {
      &.mat-mdc-menu-panel {
        ul {
          li {
            button {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  // Mat Card CSS
  .mat-mdc-card {
    &.tagus-card {
      padding: 20px;

      .mat-mdc-card-header {
        margin-bottom: 20px;

        .btn {
          padding: 0 0 2px 23px !important;
          font-size: 14px !important;

          &::before {
            font: {
              size: 14px;
            }

            ;
          }
        }

        &.border-bottom {
          padding-bottom: 15px;
        }
      }

      .mat-mdc-card-content {
        .tagus-mat-table {
          -webkit-overflow-scrolling: touch;

          overflow: {
            x: auto;
            y: hidden;
          }

          ;

          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                padding: 15px 20px;
                white-space: nowrap;

                &::after {
                  height: 15px;
                }
              }
            }

            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  padding: 15px 20px;
                  white-space: nowrap;

                  .product-info {
                    margin-right: 0;
                    padding-right: 35px;

                    img {
                      width: 45px;
                    }
                  }
                }
              }
            }
          }

          &.teamMembersListTable {
            .mat-mdc-table {
              .mdc-data-table__header-row {
                .mdc-data-table__header-cell {
                  padding: {
                    left: 20px;
                    right: 20px;
                  }

                  ;
                }
              }

              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    padding: {
                      left: 20px;
                      right: 20px;
                    }

                    ;

                    .user-info {
                      padding-right: 35px;
                      margin-right: 0;
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }

          &.invoiceListsTable {
            .mat-mdc-table {
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    .user-info {
                      padding-right: 40px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.enrolled-created-box {
        .mat-mdc-tab-group {
          .mat-mdc-tab-header {
            .mat-mdc-tab-label-container {
              .mat-mdc-tab-list {
                .mat-mdc-tab-labels {
                  .mat-mdc-tab {
                    padding: 14px 25px;
                    font-size: 16px;
                  }
                }
              }
            }
          }

          .mat-mdc-tab-body-wrapper {
            .mat-mdc-tab-body {
              .mat-mdc-tab-body-content {
                .mat-elevation-z8 {
                  .mat-mdc-table {
                    .mdc-data-table__content {
                      .mdc-data-table__row {
                        .mdc-data-table__cell {
                          .course-info {
                            margin-right: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // Calendar CSS
  .fc {
    .fc-daygrid-block-event {

      .fc-event-time,
      .fc-event-title {
        padding: 0 3px;
        font-size: 13px;
      }
    }
  }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

  // Mat Card CSS
  .mat-mdc-card {
    &.tagus-card {
      .mat-mdc-card-content {
        .tagus-mat-table {
          -webkit-overflow-scrolling: touch;

          overflow: {
            x: auto;
            y: hidden;
          }

          ;

          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                white-space: nowrap;
              }
            }

            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  white-space: nowrap;

                  .product-info {
                    padding-right: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // Calendar CSS
  .fc {
    .fc-daygrid-block-event {

      .fc-event-time,
      .fc-event-title {
        padding: 0 10px;
        font-size: 14px;
      }
    }
  }
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {

  // Mat Card CSS
  .mat-mdc-card {
    &.tagus-card {
      .mat-mdc-card-content {
        .tagus-mat-table {
          -webkit-overflow-scrolling: touch;

          overflow: {
            x: auto;
            y: hidden;
          }

          ;

          .mat-mdc-table {
            .mdc-data-table__header-row {
              .mdc-data-table__header-cell {
                white-space: nowrap;
              }
            }

            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  white-space: nowrap;

                  .product-info {
                    padding-right: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Min width 1600px */
@media only screen and (min-width: 1600px) {

  // Default BTN
  .default-btn {
    font-size: 14px;
    padding: 11px 42px 11px 22px;

    i {
      right: 22px;
      margin-top: 1px;
    }
  }

  // Mat Card CSS
  .mat-mdc-card {
    &.tagus-card {
      .mat-mdc-card-content {
        .tagus-mat-table {
          .mat-mdc-table {
            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  .product-info {
                    margin-right: -80px;
                    padding-right: 0;
                  }
                }
              }
            }
          }

          &.teamMembersListTable {
            overflow: {
              x: hidden;
              y: hidden;
            }

            ;

            .mat-mdc-table {
              .mdc-data-table__content {
                .mdc-data-table__row {
                  .mdc-data-table__cell {
                    .user-info {
                      margin-right: -80px;
                      padding-right: 0;
                    }
                  }
                }
              }
            }
          }

          &.myTasksTable {
            overflow: {
              x: hidden;
              y: hidden;
            }

            ;

            .mdc-data-table__content {
              .mdc-data-table__row {
                .mdc-data-table__cell {
                  padding: 15px 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}